import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import {Link} from "react-router-dom";


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Service" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Terms Of Service"
                    page="Terms Of Service"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <p>These Legal Terms constitute a legally binding agreement made between you,
                                        whether personally or on behalf of an entity, hereafter "you" and Coderwise. You
                                        agree that by purchasing and accessing the services that you have read,
                                        understood and agreed to be bound by these Legal Terms.</p>
                                    <h4>Our Services</h4>
                                    <p>Coderwise performs interviewing services for you, in person
                                        as well as virtually using online audio and video conferencing services. We
                                        reserve the right to change the use of specific conferencing services in line
                                        with our <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy
                                            Policy</Link>.
                                    </p>

                                    <p>As part of rendering our Services to you we provide professional opinions on the
                                        proficiency of candidates, as supplied by you for specific software engineering
                                        or similar IT related jobs and roles as described to us by you. To be able to do
                                        this effectively, we rely on you to provide us specific details on candidates,
                                        as well as for the roles you want us to interview for, including but not limited
                                        to: past work experience of the candidate, required experience for the role,
                                        technologies employed by the team the role is being hired for, team size, and
                                        level of desired proficiency of the successful candidate.</p>

                                    <p>Coderwise also relies on the cooperation and timely response of candidates.
                                        Candidates that do not attend scheduled interviews, do not complete an interview
                                        once it has commenced, do not actively participate in the interview despite
                                        being present, refuse to complete coding assessments, refuse to answer questions
                                        about their work experience cannot be effectively interviewed. We classify such
                                        candidate interviews as "failed" interviews, for which you will be billed in
                                        the same way as successful interviews, regardless of the outcome.</p>

                                    <p>Coderwise is not a recruitment agency and does not source candidates. This
                                        specifically includes not providing replacement candidates for candidates that
                                        fail an interview.</p>

                                    <h4>Use of AI / Assistive Technology</h4>
                                    <p>Coderwise employs assistive technology detection tools during our live coding
                                        interviews. Candidates are made aware at the beginning of the interview that use
                                        of such technology (i.e. AI / copy&paste) is prohibited. Once we become aware
                                        a candidate is using assistive technology beyond reasonable doubt, we may take
                                        note of this during the interview. We may or may not confront candidates with
                                        this information directly at our sole discretion. Coderwise does not explain,
                                        or substantiate how it's AI detection works and assessments are final.
                                        Candidates specifically cannot appeal should they be dissatisfied with our
                                        assessment.</p>

                                    <h4>Fairness</h4>
                                    <p>Coderwise prides itself in facilitating an equitable interview process. Our service is
                                        provided in an inclusive way and we strive to provide an environment where fair
                                        and accurate assessments of a candidates software engineering skills and
                                        proficiency are performed, regardless of a candidates age, gender, ethnicity,
                                        religious affiliation, sexual orientation or visual appearance. Coderwise
                                        encourages candidates to share their pronouns.</p>

                                    <p>Coderwise does not accept additional evaluation criteria supplied by potential
                                        employers or agencies unless fair and equitable as defined above. If directed by
                                        a potential employer or agency to ask a candidate questions of a sensitive
                                        nature, specifically to reveal sensitive personal information as defined in
                                        our <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link>.
                                        We will refuse to do so and conduct the remainder of the interview as if the
                                        additional evaluation criteria did not exist. Where audio/video recordings of
                                        interviews are desired by an employer or agency, we will ask the candidate to
                                        provide prior consent. Refusal to consent to being recorded by a candidate will
                                        have no impact on the interview outcome and it does not entitle the customer
                                        ("you") to a refund.
                                    </p>

                                    <h4>Best Effort</h4>
                                    <p>Coderwise strives to provide high-quality evaluations of candidates skills and
                                        our interviewers themselves are experienced software engineers. With this in
                                        mind, we provide our professional opinion on a candidate's suitability for a
                                        particular role on a best effort basis. We do not guarantee the accuracy,
                                        completeness, or usefulness of any evaluation or opinion provided and while
                                        we make recommendations, we do not and cannot make individual hiring decisions.
                                    </p>

                                    <h4>Limited Liability</h4>
                                    <p>To the maximum extent permitted by law, Coderwise its
                                        affiliates, and their respective officers, directors, employees, agents,
                                        licensors, and service providers will not be liable for any indirect,
                                        incidental, special, consequential, or punitive damages, including without
                                        limitation, loss of profits, data, use, goodwill, or other intangible losses,
                                        resulting from your use of our Service.</p>

                                    <h4>Governance</h4>
                                    <p>Our business is located in Sydney, NSW where all disputes shall be resolved
                                        and court shall be located. The service is governed by the laws of
                                        Australia. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;
