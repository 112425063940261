import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Privacy Policy"
                    page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">This Privacy policy was published on June 10th, 2024.</h5>
                                    </div>

                                    <h4>Your Privacy</h4>
                                    <p>At Coderwise, we are committed to protecting your privacy as a customer and the
                                        privacy of interview candidates we work with. We use the information we collect
                                        during this process primarily to provide our service to you, the customer. We
                                        respect the privacy and confidentiality of the information provided by you and
                                        adhere to the Australian Privacy Principles. Please read our privacy policy
                                        below carefully.
                                    </p>

                                    <h4>Information We Collect From You</h4>
                                    <p>During your visits to our website or use of our service, we may obtain the
                                        following information about you, interview candidates, hiring managers and
                                        referees: name, company name, email address, telephone number, residential and
                                        billing addresses, data about individual's social media profiles, as well as
                                        current and past employer details.</p>

                                    <p> Our services are not directed at persons under the age
                                        of 18 and we do not knowingly collect Personal Data from anyone under 18. If we
                                        become aware we have been provided with personal data of a person under the age
                                        of 18, we will delete that information as quickly as possible. You can request
                                        to review, correct, update or delete your Personal Data by contacting us via
                                        email at simon@coderwise.com.au</p>

                                    <h4>How We Use Your Information</h4>
                                    <p className="mb--20">Personally Identifiable Information: We use the information we
                                        collect to deliver our services to you, including communicating with you, and
                                        primarily for the purpose of providing the interviewing service.</p>

                                    <p>We do not use your Personal Information, and the Personal Information of
                                        candidates for marketing purposes unless we obtain prior explicit consent by the
                                        individual. We do not sell Personal Information for marketing purposes to any
                                        third-parties.
                                    </p>

                                    <p> Non-Personally Identifiable Information: We also use the information
                                        we collect in aggregated and anonymized forms to improve our services, including
                                        administering our website, producing reports and analytics, and advertising our
                                        service. Any information you choose to make publicly available, such as
                                        testimonials on our website, will be available for others to see. If you
                                        subsequently remove this information, copies may remain viewable in cached and
                                        archived pages on other websites or if others have copied or saved the
                                        information.</p>

                                    <h4>Sensitive Data</h4>
                                    <p className="mb--20">Sensitive Data is a subset of personal information as defined
                                        by the Australian Privacy Act. It includes information about racial or ethnic
                                        origin, political opinions, membership of political associations, religious
                                        beliefs or affiliations, sexual preferences or practices, health related data
                                        and data regarding an individuals criminal record.

                                        Coderwise do not collect, process or store sensitive Personal Data for
                                        the rendition of it's service. Where we become aware we have been exposed to
                                        an individual's sensitive data we will delete this data as quickly as
                                        possible.</p>

                                    <h4>Storage and Security Of Your Information</h4>
                                    <p>We will use all reasonable means to protect the confidentiality of your Personal
                                        Data while in our possession or control. All information we receive from you is
                                        stored and protected on our secure servers from unauthorised use or access. To
                                        enable us to deliver our services, we may transfer information that we collect
                                        about you, including Personal Data, across borders for storage and processing in
                                        countries other than Australia. If your Personal Data is transferred and
                                        processed outside Australia, it will only be transferred to countries that have
                                        adequate privacy protections. We retain your personal information for as long as
                                        needed to provide services to you and as otherwise necessary to comply with our
                                        legal obligations, resolve disputes and enforce our agreements. In the event
                                        there is a breach of our security and your Personal Data is compromised, we will
                                        promptly notify you in compliance with the applicable law..</p>

                                    <h4>Sharing Your Information With Third Parties</h4>
                                    <p className="mb--20">We do not and will not sell or deal in Personal Data or any
                                        customer information. Your Personal Data details are only disclosed to third
                                        party suppliers when it is required by law, for the rendition of services which
                                        you have purchased, for payment processing or to protect our copyright,
                                        trademarks and other legal rights. To the extent that we do share your Personal
                                        Data with a service provider, we would only do so if that party has agreed to
                                        comply with our privacy standards as described in this privacy policy and in
                                        accordance with applicable law. Our contracts with third parties prohibit them
                                        from using any of your Personal Data for any purpose other than that for which
                                        it was shared.</p>

                                    <h4>Disclosure Of Your Information</h4>
                                    <p className="mb--20">We may from time to time need to disclose certain information,
                                        which may include your Personal Data, to comply with a legal requirement, such
                                        as a law, regulation, court order, subpoena, warrant, in the course of a legal
                                        proceeding or in response to a law enforcement agency request. Also, we may use
                                        your Personal Data to protect the rights, property or safety of
                                        coderwise.com.au, our customers or third parties. If there is a change of
                                        control in one of our businesses (whether by merger, sale, transfer of assets or
                                        otherwise) customer information, which may include your Personal Data, could be
                                        transferred to a purchaser under a confidentiality agreement. We would only
                                        disclose your Personal Data in good faith and where required by any of the above
                                        circumstances.</p>

                                    <h4>Change In Privacy Policy</h4>
                                    <p className="mb--20">As we plan to ensure our privacy policy remains current, this
                                        policy is subject to change. We may modify this policy at any time, in our sole
                                        discretion and all modifications will be effective immediately upon our posting
                                        of the modifications on this website. Please return periodically to review our
                                        privacy policy.</p>

                                    <h4>Contact Us</h4>
                                    <p className="mb--20">If you have any questions or concerns at any time about our
                                        privacy policy or the use of your Personal Data, please contact us at
                                        simon@coderwise.com.au and we will respond in a timely manner.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;
