import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BannerTwo from '../component/banner/BannerTwo';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CounterUpTwo from "../component/counterup/CounterUpTwo";
import VideoOne from "../component/video/VideoOne";
import VideoTwo from "../component/video/VideoTwo";


const CreativeAgency = () => {

    return (
        <>
        <SEO title="Coderwise"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderTwo />
            <BannerTwo />
            <CounterUpTwo />
            {/*<VideoTwo/>*/}
            <TestimonialOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default CreativeAgency;

