import React from 'react';
import { Link } from 'react-router-dom';
import CounterUp from './CounterUp';


const CounterUpTwo = () => {
    return (
        <div className="section section-padding expert-counterup-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="section-heading heading-left">
                            <span className="subtitle">We know software engineering</span>
                            <h2>Why outsource your technical interview?</h2>
                            <p className="mb--50">&#8226; Our interviewers are hands-on engineers with team building
                                experience, not recruiters.</p>
                            <p className="mb--50">&#8226; Candidates and interviewer perform a live pair programming exercise, not a take-home test. We get to know candidates, expose them to fundamental, real-world programming concepts and assess their level of craftsmanship.</p>
                            <p className="mb--50">&#8226; No AI here. While our platform has advanced, built-in plagiarism detection, protecting against copy/paste cheating <b>our interviewer is present the entire time</b>.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-xl-1">
                    <div className="row">
                            <CounterUp colSize="col-sm-6" layoutStyle="counterup-style-2" evenTopMargin="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CounterUpTwo;
